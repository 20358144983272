<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="options.width"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    origin="center center"
    transition="scale-transition"
  >
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              ref="txtComment"
              v-model.trim="form.str1"
              :rules="[rules.required, rules.limitComment]"
              label="댓글"
              :maxlength="commentLimit"
              :counter="commentLimit"
              required
              autofocus
              @keydown.13.stop="agree"
              @blur="$refs.txtComment.resetValidation()"
            >
              <v-icon slot="prepend">mdi-comment-text-outline</v-icon>
            </v-text-field>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small text color="primary"
              @click="agree"
            >
              <v-icon small class="mr-2">mdi-lead-pencil</v-icon>등록
            </v-btn>
            <v-btn small text color="error"
              @click="cancel"
            >
              <v-icon small class="mr-2">mdi-cancel</v-icon>취소
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    resolve: null, // 이 방법이 너무 참신하다!
    reject: null,
    options: {
      color: 'primary',
      width: 650
    },
    showMenu: false,
    x: 0,
    y: 0,
    // 구분: 폼
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 37, // !! 불변 - 유형(댓글)
      depth: 2, // !! 불변(게시판)
      pId: 0, // 부모(게시판) 아이디
      pType: 4, // 부모타입(게시판)
      subtype: 1, // 등록을 제외한 업무는 1로 세팅(등록은 자동입력데이터임)
      gubun1: '댓글',
      status: 1,
      str1: '' // 내용
    },
    // 구분: 댓글 글자수 제한
    commentLimit: 50
  }),

  computed: {
    // data 에서는 this를 쓸 수 없으므로 computed 에서
    rules () {
      return {
        required: value => !!value || '입력값은 필수입니다',
        limitComment: value => value.length <= this.commentLimit || `글자수는 ${this.commentLimit}자 내외로 해야 합니다`
      }
    }
  },

  watch: {
    'form.str1': {
      handler: function (val, oldVal) {
        // 한글 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        if (val.length > this.commentLimit) {
          this.form.str1 = oldVal
          this.$refs.txtComment.lazyValue = oldVal
        }
      }
    }
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화
    async init () {
      try {
        this.$refs.txtComment.resetValidation()

        this.form.id = 0
        this.form.teamId = 1
        this.form.pId = 0
        this.form.str1 = ''
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 메뉴 팝업을 띄우는 함수
    async show (evt) {
      try {
        if (!this.showMenu) { // !! 안전장치 - 이미 열려있으면 다시 열리지 않게 한다.
          evt.preventDefault()
          this.showMenu = false
          this.x = evt.clientX
          this.y = evt.clientY

          this.$nextTick(() => {
            this.showMenu = true
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // data setting
    async setData (item) {
      try {
        if (!item) throw new Error(`잘못된 인자 형식입니다.`)

        // !! 폼값 세팅 : 나머지 정보를 입력한다(부모와 조부의 정보)
        this.form.teamId = item.teamId // 팀아이디
        this.form.pId = item.pId // 부모(게시물) 아이디

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    async agree () {
      try {
        if (!this.$refs.txtComment.validate()) {
          this.$refs.txtComment.focus() // 자연스럽게 보이기 위해 포커싱
          throw new Error(`입력값을 확인하세요.`)
        }

        // !! DB 처리(입력/수정)
        const { data } = await this.$axios.post(`lawork/bbs/setComment`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        await this.init()
        this.resolve(true)
        this.showMenu = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    async cancel () {
      try {
        await this.init()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.showMenu = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
