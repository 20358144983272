<template>
  <v-container fluid class="pa-0 ma-0">

    <v-toolbar dense flat>
      <!-- 이전글 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab text small
            :disabled="prevId <= 0"
            @click="goPrev"
          >
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <span>이전글</span>
      </v-tooltip>
      <!-- 목록 -->
      <v-btn text small @click="goList">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>
      <!-- 다음글 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab text small
            :disabled="nextId <= 0"
            @click="goNext"
          >
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <span>다음글</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <!-- 수정버튼 -->
      <v-btn
        text small color="success"
        @click="bbsEdit"
      >
        <v-icon small>mdi-pencil</v-icon>
        수정
      </v-btn>
      <!-- 삭제버튼 -->
      <v-btn
        text small color="error"
        @click="deleteArticle"
      >
        <v-icon small>mdi-trash-can-outline</v-icon>
        삭제
      </v-btn>
    </v-toolbar>

    <v-row
      v-if="!isId"
      no-gutters
    >
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-alert
          prominent
          type="warning"
          class="ma-4 pa-10"
        >존재하지 않는 데이터 입니다</v-alert>
      </v-col>
    </v-row>
    <v-row
      v-else
      align="center"
      justify="center"
      no-gutters
    >
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <div
          class="py-4 px-2"
        >
          <v-icon small
            v-show="article.isStar"
            class="pb-1"
            color="amber darken-1"
          >mdi-star</v-icon>
          <!-- <v-chip
            v-show="article.isNotice"
            small outlined label class="ml-2 mb-1" color="warning"
          >
            <v-icon x-small left>mdi-bell-alert</v-icon> 공지
          </v-chip> -->
          <v-btn
            v-show="article.gubun1"
            x-small dark depressed
            class="mx-2 mb-1 py-3"
            :color="article.teamColor"
            style="font-size: 0.785rem !important;font-weight: 600;cursor: default;"
          >
            {{ article.gubun1 }}
          </v-btn>
          <span
            v-show="article.isNotice"
            class="text-h6 warning--text"
          >[공지]</span>
          <span class="text-h6">
            {{ article.subject }}
          </span>
          <!-- <span class="text-body-2 grey--text mx-2">
            {{ strDateFormat2(article.createdAt) }} {{ article.wname }} 작성
          </span> -->
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="pb-3">
        <div class="pt-3 px-2 text-subtitle-2 grey--text text--darken-1">공개</div>
        <div class="px-2 text-body-2">
          <!-- <div class="my-1 text-body-2">
            <v-chip
              class="pa-2" outlined
              :color="article.teamColor"
            >
              <v-icon small left class="ml-1 mr-2">mdi-account-group</v-icon>
              {{ article.teamName }}
            </v-chip>
            <span class="text-body-2 grey--text mx-2">
              {{ strDateFormat2(article.createdAt) }} {{ article.wname }} 작성
            </span>
          </div> -->
          <div class="my-1 text-body-2">
            <span class="ml-2 mr-1">
              {{ strDateFormat2(article.updatedAt) }} {{ article.wname }}
            </span>
            <v-icon small>mdi-link-variant</v-icon>
            <span>
              [{{ article.teamName }}]
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="pt-2 pb-1">
        <span class="ml-2 text-subtitle-2 grey--text text--darken-1">첨부파일 + 공유링크</span>
        <!-- <span class="text-subtitle-1 font-weight-bold grey--text text--darken-1 ml-4">첨부파일 + 공유링크</span> -->
        <!-- @: 첨부파일 추가 아이콘 -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              :color="mdiListEditButtonColor"
              class="ml-3"
              @click="showFilePop"
            >mdi-paperclip</v-icon>
          </template>
          <span>첨부파일등록</span>
        </v-tooltip>
        <!-- @: 공유링크 추가 아이콘 -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              :color="mdiListEditButtonColor"
              class="ml-3"
              @click="showShareLinkPop"
            >mdi-link-plus</v-icon>
          </template>
          <span>공유링크등록</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="pt-0 pb-1 px-2">
        <!-- 첨부파일 목록 -->
        <div
          v-for="file in attachedFiles"
          :key="file.id"
          class="my-1 text-body-2"
        >
          <v-hover v-slot:default="{ hover }" transition="fade-transition">
            <div class="ma-0 pa-0">
              <div class="pl-1">
                <v-icon
                  :color="file.isAnotherWrite ? 'purple' : ''"
                  x-small
                >
                  {{ file.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                </v-icon>
                <v-icon v-show="file.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                <v-icon v-show="file.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) 컴포넌트 -->
                <attach-file-category-tag
                  v-show="file.type === 36 || file.type === 38"
                  :caption="file.gubun2"
                ></attach-file-category-tag>
                {{ file.str1 }}
                <span class="ml-1 text-caption grey--text">
                  ({{ strDateFormat2(file.createdAt) }} {{ file.wname }})
                </span>
                <!-- 사용안함:[2021.9.1] 첨부파일 다운로드 -->
                <!-- <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="hover && file.type === 36"
                      v-on="on"
                      small
                      right
                      :color="mdiDownloadButtonColor"
                      class="ml-3"
                      @click="fileDownload(file)"
                    >mdi-cloud-download-outline</v-icon>
                  </template>
                  <span>다운로드</span>
                </v-tooltip> -->
                <!-- 첨부파일 바로보기 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="hover && file.type === 36"
                      v-on="on"
                      small
                      right
                      :color="mdiOpenFileButtonColor"
                      class="ml-3"
                      @click="fileDirectView(file)"
                    >mdi-eye-outline</v-icon>
                  </template>
                  <span>바로보기</span>
                </v-tooltip>
                <!-- 첨부파일 삭제 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="hover && file.type === 36"
                      v-on="on"
                      small
                      right
                      :color="mdiTrashCanOutlineButtonColor"
                      class="ml-3"
                      @click="deleteAttachFile(file)"
                    >mdi-trash-can-outline</v-icon>
                  </template>
                  <span>삭제</span>
                </v-tooltip>
                <!-- 공유링크 바로가기 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="hover && file.type === 38"
                      v-on="on"
                      small
                      right
                      :color="mdiPlusBoxOutlineButtonColor"
                      class="ml-3"
                      @click="shareLinkDirectGo(file)"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
                <!-- 공유링크 삭제 -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="hover && file.type === 38"
                      v-on="on"
                      small
                      right
                      :color="mdiTrashCanOutlineButtonColor"
                      class="ml-3"
                      @click="deleteAttachFile(file)"
                    >mdi-trash-can-outline</v-icon>
                  </template>
                  <span>삭제</span>
                </v-tooltip>
              </div>
            </div>
          </v-hover>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <div class="pt-3 px-2 text-subtitle-2 grey--text text--darken-1">내용</div>
      </v-col>
      <!-- !![2021.4.23]내용 들어가는 영역 수정: 읽기 편한 사이즈로 글씨 조정 -->
      <v-col cols="12" xs="12" sm="12">
        <v-card
          elevation="0"
          class="mx-1 my-0 px-1 py-3"
        >
          <v-card-text class="text--center grey--text text--darken-4 px-3 py-0">
            <div
              id="vue2editorViewer"
              style="font-size: 0.975rem;font-weight: 400;line-height: 1.85;"
              v-html="article.content"
            ></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="pt-2 pb-1 px-2">
        <span class="text-subtitle-2 grey--text text--darken-1">댓글</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              small
              :color="mdiPlusBoxOutlineButtonColor"
              class="ml-3"
              @click="showCommentPop"
            >mdi-comment-text-outline</v-icon>
          </template>
          <span>댓글등록</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" class="pt-0 pb-10 px-2">
        <!-- 댓글 목록 -->
        <div
          v-for="(comment, i) in comments"
          :key="i"
          class="my-1 text-body-2"
        >
          <v-hover v-slot:default="{ hover }" transition="fade-transition">
            <div class="ma-0 pa-0">
              <div class="pl-1">
                <v-icon
                  :color="comment.isAnotherWrite ? 'purple' : ''"
                  x-small
                >
                  {{ comment.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                </v-icon>
                {{ comment.str1 }}
                <span class="text-caption grey--text">({{ strDateFormat2(comment.createdAt) }} {{ comment.wname }})</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="hover"
                      v-on="on"
                      small
                      right
                      :color="mdiTrashCanOutlineButtonColor"
                      class="ml-3"
                      @click="deleteComment(comment.id)"
                    >mdi-trash-can-outline</v-icon>
                  </template>
                  <span>삭제</span>
                </v-tooltip>
              </div>
            </div>
          </v-hover>
        </div>
      </v-col>

      <!-- !! 하단여백 -->
      <v-col cols="12" class="my-12 py-5">
        <div></div>
      </v-col>

    </v-row>

    <!-- !! 게시물 수정 다이얼로그 -->
    <bbs-add-dialog ref="bbsAddDialog" :article="article"></bbs-add-dialog>
    <!-- !! 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 댓글 팝업 -->
    <add-comment-pop ref="addCommentPop"></add-comment-pop>
    <!-- 첨부파일 팝업 -->
    <add-file-pop ref="addFilePop"></add-file-pop>
    <!-- 공유링크 팝업 컴포넌트 -->
    <add-share-link-pop ref="addShareLinkPop"></add-share-link-pop>

  </v-container>
</template>

<script>
// @: 스토리지
import storage from '@/lib/storage'

// @: 필터
import strDateFormat2 from '@/filters/strDateFormat2'

// @: popups
import bbsAddDialog from '@/components/bbs/AddDialog' // 게시판 수정 팝업
import addCommentPop from '@/components/bbs/addCommentPop' // 댓글 팝업
import addFilePop from '@/components/bbs/addFilePop' // 첨부파일 팝업
import addShareLinkPop from '@/components/bbs/addShareLinkPop' // 공유링크 팝업

// @: tag components
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그

// * 현재 dir
const currDir = 'bbs'

export default {
  components: {
    bbsAddDialog,
    addCommentPop,
    addFilePop,
    addShareLinkPop,
    attachFileCategoryTag
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 게시물 데이터
    articleId: 0,
    article: {},
    prevId: 0,
    nextId: 0,
    // 구분: 댓글 목록
    comments: [],
    // 구분: 첨부파일 목록(첨부파일 + 공유링크)
    attachedFiles: [],
    overlay: false, // 첨부파일 다운로드 로딩시 오버레이
    // 구분: 존재하는 아이디 인지 여부
    isId: true
  }),

  watch: {
    // !!중요: /bbs 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // !! 넘겨준 파라미터가 있는 경우. 등록시엔 -R을 제거해야 한다.
      const paramIds = to.params.id.split('-')
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 수정시엔 '-E' 가 붙어서 넘어온다
        this.articleId = parseInt(paramIds[0], 10)
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        this.articleId = parseInt(paramIds[0], 10)
        // 넘어온 id로 해당 게시물 정보를 가져와야 한다
        this.getArticle(this.articleId)
      }
    }
  },

  mounted () {
    // 중요: 넘어온 id를 매칭시켜야 한다!
    // 등록시엔 '-R' 이 붙어오므로 분리해서 적용한다. 안그러면 뷰페이지에서 목록으로 보낼때마다 초기화 해버린다.
    const paramIds = this.$route.params.id.split('-')
    this.articleId = parseInt(paramIds[0], 10)

    // 넘어온 id로 해당 게시물 정보를 가져와야 한다
    this.getArticle(this.articleId)
  },

  methods: {
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    // !! 목록으로
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // !! 이전글
    goPrev () {
      if (this.prevId) {
        this.articleId = this.prevId // 중요: 이게 빠지면 난리남
        this.$router.push(`/${currDir}/${this.articleId}`)
      }
    },
    // !! 다음글
    goNext () {
      if (this.nextId) {
        this.articleId = this.nextId // 중요: 이게 빠지면 난리남
        this.$router.push(`/${currDir}/${this.articleId}`)
      }
    },
    // id로 게시물을 가져온다
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        // 중요: 로컬스토리지에 저장한 params 정보를 가져온다.
        // - 이전글, 다음글 id 패칭을 위해 리스트 검색값을 가져와야 함
        const bbsParams = storage.get('bbsParams')

        const { data } = await this.$axios.get(`lawork/${currDir}/getArticle/${id}`, { params: bbsParams })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          this.article = data.article
          //
          this.prevId = data.prevId
          this.nextId = data.nextId
          //
          // 중요: 서브 컨텐츠인 댓글,첨부파일,공유링크 목록 패칭
          await this.getSubContents(36) // 첨부파일 + 공유링크
          await this.getSubContents(37) // 댓글
          //
        } else {
          // throw new Error('존재하지 않는 데이터 입니다!')
          this.isId = false
          this.overlay = true
          setTimeout(this.goList, 1000)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 게시물 삭제처리
    async deleteArticle () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // console.log(this.articleId)
          const { data } = await this.$axios.get(`lawork/${currDir}/deleteArticle/${this.articleId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          this.$router.push(`/${currDir}/LR`) // 삭제하고 리스트리프레시로 보낸다
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 게시물 수정팝업
    async bbsEdit () {
      try {
        const result = await this.$refs.bbsAddDialog.open('게시판', { width: 800 }, this.articleId, this.article)
        // !! 정상적인 수정이 완료되면 등록된 아이디를 리턴받아서 뷰페이지로 보낸다.
        if (result) {
          // 중요: 수정시엔 리프레시를 의미하는 '-E' 을 붙여서 보낸다.
          const editedId = `${result}-E`
          this.$router.push(`/${currDir}/${editedId}`)

          // 중요: 토스트 뷰어 리프레시
          // this.$refs.tuiViewer.invoke('setMarkdown', this.article.content)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 서브 컨텐츠: 댓글, (첨부파일 + 공유링크) 처리 ---
    // !! 첨부파일(36),공유링크(38) 은 둘중 어느것을 선택하더라도 같이 처리된다. attachedFiles[] 배열에 들어감!
    async getSubContents (type = null) {
      try {
        const columns = { bbsId: this.articleId, bbsType: 4, type }
        const { data } = await this.$axios.post(`lawork/${currDir}/getSubContents`, columns)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // !! 그냥 (첨부파일+공유링크), 댓글로 처리한다.
        if (type === 36 || type === 38) {
          this.attachedFiles = data.list
        } else if (type === 37) {
          this.comments = data.list
        }

        // !! 소송/자문, 인명부와 다르게 아래처럼 처리할 수 없다.
        // let rels = [
        //   { type: 36, name: 'attachedFiles' }, // 첨부파일
        //   { type: 37, name: 'comments' }, // 댓글
        //   { type: 38, name: 'attachedFiles' } // 공유링크
        // ]
        //
        // // 넘어온 타입이 있는 경우
        // if (type) {
        //   const [ r ] = rels.filter(c => c.type === type)
        //   const list = data.list.filter(c => c.type === r.type)
        //   this[r.name] = list
        //   //
        // } else { // 넘어온 타입이 없다(전체)
        //   rels.forEach(async (r) => {
        //     const list = data.list.filter(c => c.type === r.type)
        //     this[r.name] = await this.setSubContentList({ type: r.type, list })
        //   })
        // }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 댓글 ---
    // 댓글 팝업 열기
    async showCommentPop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addCommentPop.showMenu) {
          await this.$refs.addCommentPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const params = { teamId: this.article.teamId, pId: this.article.id }
          const result = await this.$refs.addCommentPop.setData(params)
          if (result) {
            await this.getSubContents(37) // !! 댓글 목록 리프레시
            this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 댓글 삭제
    // 주의: 삭제는 case/deleteLwcommon 를 사용한다!
    // 중요:[2021.7.14] bbs/deleteComment 를 사용하도록 수정되었다.
    async deleteComment (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${commentId}`)
          const { data } = await this.$axios.get(`lawork/bbs/deleteComment/${this.articleId}/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // 정상적으로 등록되었으면 진행내용 리프레시
          await this.getSubContents(37) // !! 댓글 목록 리프레시
          this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 첨부파일 ---
    // 첨부파일 팝업열기
    async showFilePop (evt) {
      try {
        // * 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addFilePop.showMenu) {
          await this.$refs.addFilePop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const params = { teamId: this.article.teamId, pId: this.article.id }
          const result = await this.$refs.addFilePop.setData(params)
          if (result) {
            await this.getSubContents(36) // 첨부파일 목록 리프레시
            this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
          }
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 사용안함: 첨부파일 다운로드
    // async fileDownload (file) {
    //   try {
    //     if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
    //     this.overlay = true // 로딩 오버레이 on
    //     const webContentLink = file.str5 // 이건 다운로드

    //     if (this.$store.state.ui.lv === 'M') {
    //       // !! 관리자인 경우는 그냥 다운로드
    //       document.location.href = webContentLink // 다운로드 시작!
    //       // 2초간 로딩 딜레이
    //       setTimeout(() => {
    //         this.overlay = false // 로딩 오버레이 off
    //       }, 2000)
    //     } else {
    //       // !! 관리자가 아닌 경우 .. 공유요청
    //       const formData = {
    //         fileId: file.str2 // 구글 드라이브 파일 아이디
    //       }
    //       const { data } = await this.$axios.post(`lawork/${currDir}/requestGdFileShare`, formData)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
    //       if (data.permessionId) {
    //         document.location.href = webContentLink // 다운로드 시작!
    //         // 2초간 로딩 딜레이
    //         setTimeout(() => {
    //           this.overlay = false // 로딩 오버레이 off
    //         }, 2000)
    //       }
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // * 관리자인 경우 - 새탭을 열고 바로보기로 보낸다.
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // * 관리자가 아닌 경우 - 해당 파일에 대한 허가를 요청한다..
          const formData = {
            fileId: file.str2 // * 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/${currDir}/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // * 넘겨받은 퍼미션아이디가 유효해야 한다.
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 첨부파일 + 공유링크를 모두 삭제
    async deleteAttachFile (file) {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        let msg = ''
        if (file.type === 36) {
          msg = `정말 삭제하시겠습니까?<br>관리자의 구글 드라이브에는 유지됩니다.`
        } else if (file.type === 38) {
          msg = `정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다.`
        }
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // 삭제할 첨부파일 아이디와 부모 아이디를 전송
          const formData = { fileId: file.id, pId: this.articleId }
          const { data } = await this.$axios.post(`lawork/${currDir}/deleteAttachFile`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          await this.getSubContents(36) // 첨부파일 목록 리프레시
          this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 공유링크 처리 ---
    // 공유링크 팝업열기
    async showShareLinkPop (evt) {
      try {
        // * 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addShareLinkPop.showMenu) {
          await this.$refs.addShareLinkPop.show(evt) // 일단 열고

          // 나머지 인자를 보낸다
          const params = { teamId: this.article.teamId, pId: this.article.id }
          const result = await this.$refs.addShareLinkPop.setData(params)
          if (result) {
            await this.getSubContents(38) // 첨부파일 목록 리프레시
            this.refreshList(`${this.articleId}-E`) // 리스트 리프레시 - 주의: 리스트까지 리프레시 하기위해
          }
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        // console.log(item.str2)
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
